exports.components = {
  "component---node-modules-parallelpublicworks-gatsby-clf-material-layouts-preview-js": () => import("./../../../node_modules/@parallelpublicworks/gatsby-clf-material/layouts/Preview.js" /* webpackChunkName: "component---node-modules-parallelpublicworks-gatsby-clf-material-layouts-preview-js" */),
  "component---node-modules-parallelpublicworks-gatsby-clf-material-layouts-single-js": () => import("./../../../node_modules/@parallelpublicworks/gatsby-clf-material/layouts/Single.js" /* webpackChunkName: "component---node-modules-parallelpublicworks-gatsby-clf-material-layouts-single-js" */),
  "component---node-modules-parallelpublicworks-gatsby-clf-material-layouts-single-ssr-js": () => import("./../../../node_modules/@parallelpublicworks/gatsby-clf-material/layouts/SingleSSR.js" /* webpackChunkName: "component---node-modules-parallelpublicworks-gatsby-clf-material-layouts-single-ssr-js" */),
  "component---node-modules-parallelpublicworks-gatsby-clf-src-pages-oauth-callback-js": () => import("./../../../node_modules/@parallelpublicworks/gatsby-clf/src/pages/oauth/callback.js" /* webpackChunkName: "component---node-modules-parallelpublicworks-gatsby-clf-src-pages-oauth-callback-js" */),
  "component---node-modules-parallelpublicworks-gatsby-clf-src-pages-oauth-logout-js": () => import("./../../../node_modules/@parallelpublicworks/gatsby-clf/src/pages/oauth/logout.js" /* webpackChunkName: "component---node-modules-parallelpublicworks-gatsby-clf-src-pages-oauth-logout-js" */),
  "component---node-modules-parallelpublicworks-gatsby-clf-src-pages-oauth-start-js": () => import("./../../../node_modules/@parallelpublicworks/gatsby-clf/src/pages/oauth/start.js" /* webpackChunkName: "component---node-modules-parallelpublicworks-gatsby-clf-src-pages-oauth-start-js" */),
  "component---src-layouts-chapter-js": () => import("./../../../src/layouts/Chapter.js" /* webpackChunkName: "component---src-layouts-chapter-js" */),
  "component---src-layouts-publication-full-js": () => import("./../../../src/layouts/PublicationFull.js" /* webpackChunkName: "component---src-layouts-publication-full-js" */),
  "component---src-layouts-publication-js": () => import("./../../../src/layouts/Publication.js" /* webpackChunkName: "component---src-layouts-publication-js" */)
}

